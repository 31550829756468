<template>
  <page-title class="q-mb-lg" :title="$t('account.documents')" />
  <q-intersection
    class="full-height default-shadow default-rounded bg-white full-height-intersection"
    transition="slide-down"
  >
    <div
      v-if="formatOptions(docTypes).length"
      v-html="$t('account.my_docs.disclaimer')"
      class="q-pa-lg bg-grey-3 text-weight-medium text-justify"
    />

    <div
      v-if="formatOptionsHelper(docTypes).length"
      class="q-pa-xs text-white bg-red-3 q-mb-md text-weight-medium text-justify"
    >
      <ul>
        <li
          :key="i"
          v-html="doc.label"
          v-for="(doc, i) in formatOptionsHelper(docTypes)"
        />
      </ul>
    </div>

    <div class="row q-mb-xs">
      <q-space />
      <q-btn
        round
        icon="add"
        color="black"
        class="q-mr-md"
        @click="() => (showModal = true)"
        :class="{ 'q-mt-md': !formatOptions(docTypes).length > 0 }"
      />
    </div>

    <q-card class="q-mx-md q-mb-lg" flat>
      <div class="q-pa-sm q-pl-md" v-html="$t('account.my_docs.docs')" />
      <q-separator />
      <div v-if="getUserDocuments.length">
        <div v-for="(item, i) in getUserDocuments" :key="i">
          <q-card
            flat
            class="q-pr-md q-pl-md"
            :class="{ 'bg-grey-2': isOdd(i + 1) }"
          >
            <div class="row no-wrap full-width items-center q-pa-sm">
              <div>
                <b>{{ $t("account.my_docs.doc_type") }} </b>
                {{ item.slug_doc }}
              </div>
              <q-space />
              <div>
                <q-icon name="today" /> {{ formatFullDate(item.updated_at) }}
              </div>
            </div>
            <div class="q-pa-sm">
              <div>
                <b>{{ $t("account.my_docs.file_name") }} </b>
                <a :href="item.url" :download="item.original_file_name">{{
                  item.original_file_name
                }}</a>
              </div>
            </div>
            <div class="q-pa-sm" v-if="item.observation">
              <div
                class="text-weight-bold"
                v-html="$t('account.my_docs.observation')"
              />
              <div v-html="item.observation" />
            </div>
            <q-separator />
          </q-card>
        </div>
      </div>
      <div v-else class="q-pa-lg bg-grey-2 text-center">
        <div v-html="$t('account.my_docs.no_docs_submitted')" />
      </div>
    </q-card>
  </q-intersection>
  <upload-document-component
    v-model="showModal"
    :documents="getUserDocuments"
    :docsList="formatOptions(docTypes)"
    :numOfPartnerDocs="getUserDocuments.length"
    @uploaded="closeModal()"
  />
</template>

<script>
import { formatFullDate } from "@/shared/helpers/numbersManipulation";
import UploadDocumentComponent from "./UploadDocumentComponent.vue";
import { useActions, useGetters } from "vuex-composition-helpers";
import PageTitle from "@/modules/main/components/page-title";
import { onMounted, ref } from "vue";

export default {
  name: "myDocuments",

  components: {
    PageTitle,
    UploadDocumentComponent,
  },

  setup() {
    const showModal = ref(false);

    const { fetchUserDocs, fetchDocsTypes } = useActions({
        fetchUserDocs: "account/fetchUserDocs",
        fetchDocsTypes: "account/fetchDocsTypes",
      }),
      { getUserDocuments, docTypes } = useGetters({
        getUserDocuments: "account/getUserDocuments",
        docTypes: "account/getDocTypes",
      });

    const isOdd = (num) => num % 2,
      formatOptions = (options) =>
        options
          .map((option) => ({
            value: option.id,
            label: option.description,
            slug: option.slug,
          }))
          .filter(
            (option) =>
              !getUserDocuments.value.some(
                (doc) => doc.slug_doc === option.slug
              )
          );

    const formatOptionsHelper = (options) =>
      options
        .map((option) => ({
          value: option.id,
          label: option.helper,
          slug: option.slug,
        }))
        .filter(
          (option) =>
            !getUserDocuments.value.some((doc) => doc.slug_doc === option.slug)
        );

    onMounted(() => {
      fetchUserDocs();
      fetchDocsTypes();
    });

    return {
      docTypes,
      showModal,
      fetchUserDocs,
      formatFullDate,
      getUserDocuments,
      isOdd,
      formatOptions,
      formatOptionsHelper,
      closeModal: () => (showModal.value = false),
    };
  },
};
</script>
